import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IconContext } from 'react-icons';
import { FaLocationDot, FaPhone, FaRegEnvelope } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';

const iconStyle = { size: "22px", color: "white" };

const Footer = () => {
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 991px)' }); // Любые устройства с шириной до 991px

    return (
        <footer className='footer'>
            <Container>
                <Col className='mb-3 d-flex align-items-center pt-4'>
                    <a href="https://www.google.com/maps/place/Farkostv%C3%A4gen+2,+181+35+Liding%C3%B6/@59.3544469,18.1305304,17z/data=!3m1!4b1!4m6!3m5!1s0x465f82d1b7db06cf:0x3738d6e0862ba23d!8m2!3d59.3544469!4d18.1331107!16s%2Fg%2F11cnd7fzk3?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }} aria-label='Se adressen på Google Maps'>
                        <IconContext.Provider value={iconStyle}>
                            <FaLocationDot />
                        </IconContext.Provider>
                    </a>
                    <p className='ms-3 mb-0' style={{ fontFamily: 'Montserrat-Regular' }}>
                        <a href="https://www.google.com/maps/place/Farkostv%C3%A4gen+2,+181+35+Liding%C3%B6/@59.3544469,18.1305304,17z/data=!3m1!4b1!4m6!3m5!1s0x465f82d1b7db06cf:0x3738d6e0862ba23d!8m2!3d59.3544469!4d18.1331107!16s%2Fg%2F11cnd7fzk3?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }} aria-label='Se adressen på Google Maps'>
                            Farkostvägen 2, Lidingö
                        </a>
                    </p>
                </Col>
                <Col className='mb-3 d-flex align-items-center'>
                    <IconContext.Provider value={iconStyle}>
                        <FaPhone />
                    </IconContext.Provider>
                    <p className='ms-3 mb-0' style={{ fontFamily: 'Montserrat-Regular' }}>+46700056800</p>
                </Col>
                <Col className='mb-3 d-flex align-items-center'>
                    <a href="mailto:lika.tapetserare@gmail.com" target="_blank" rel='noopener noreferrer' aria-label='Skicka e-post till adressen'>
                        <IconContext.Provider value={iconStyle}>
                            <FaRegEnvelope  className='mail' />
                        </IconContext.Provider>
                    </a>
                    <a href="mailto:lika.tapetserare@gmail.com" target="_blank" rel='noopener noreferrer' aria-label='Skicka e-post till adressen'>
                        <p className='ms-3 mb-0' style={{ fontFamily: 'Montserrat-Regular' }}>lika.tapetserare@gmail.com</p>
                    </a>
                </Col>
                <Col className='mb-3 d-flex align-items-center'>
                    <a href="https://www.instagram.com/lika_tapetserare/" target="_blank" rel="noopener noreferrer" aria-label='Följ Instagram-profilen'>
                        <IconContext.Provider value={iconStyle}>
                            <BsInstagram />
                        </IconContext.Provider>
                    </a>
                    <a href="https://www.instagram.com/lika_tapetserare/" target="_blank" rel="noopener noreferrer" aria-label='Följ Instagram-profilen'>
                        <p className='ms-3 mb-0' style={{ fontFamily: 'Montserrat-Regular' }}>lika_tapetserare</p>
                    </a>
                </Col>
                <Row className='d-flex justify-content-between align-items-center'>
                    <Col className={isMobileOrTablet ? 'd-flex flex-column align-items-center mb-3' : 'd-flex flex-column align-items-start mb-3'} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <p style={{ fontFamily: 'Montserrat-Regular',  marginBottom: '0' }}>Copyright © 2024 LIKA MÖBELTAPETSERING</p>
                        <Link to="/integritetspolicy" style={{ fontFamily: 'Montserrat-Regular', color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>Integritetspolicy</Link>
                    </Col>
                    <Col className={isMobileOrTablet ? 'd-flex flex-column align-items-center mb-3' : 'd-flex justify-content-end align-items-center mb-3'} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <p style={{ fontFamily: 'Montserrat-Regular' }}>Webbsida skapad av <a href="https://www.linkedin.com/in/inna-dyachkova/" target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: '#EDD0A7'}}>Inna Dyachkova</a></p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;
