import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import ImageGallery from './components/ImageGallery';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';
import './custom-styles.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bildgalleri" element={<ImageGallery />} />
            <Route path="/integritetspolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
