import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

const About = forwardRef(({ id }, ref) => {
  return (
    <div id={id} ref={ref} className="section-container" style={{ marginTop: '111px' }}>
      <Container fluid style={{ backgroundColor: '#DDB2C0' }} className="pt-5">
        <Row className="justify-content-center mb-0">
          <Col xs={11} sm={11} md={10} lg={5} xl={5} xxl={5} className="mb-5">
            <Image src='./assets/IMG_2483.webp' alt='Lika' style={{ width: "100%", height: "auto", maxWidth: "550px" }} />
          </Col>
          <Col xs={11} sm={11} md={10} lg={5} xl={5} xxl={5} className='mb-5'>
            <h2 className="pb-3 text-start" style={{ color: '#4D3280', fontFamily: 'Montserrat-Semibold' }}>Förvandla din favoritmöbel!</h2>
            <p className="text-start mb-1 pb-1" style={{ fontFamily: 'Montserrat-Regular' }}>Lika Möbeltapetsering erbjuder professionell omklädsel och renovering av alla typer av möbler, både för privatpersoner och offentliga miljöer. Jag tar hand om allt från kuddar, pallar och sänggavlar till fåtöljer och soffor, och ger dina gamla möbler nytt liv. </p>
            <p className="text-start mb-1 pb-1" style={{ fontFamily: 'Montserrat-Regular' }}>Genom min utbildning på Malmstens har jag fått en stadig grund för en karriär inom Möbeltapetsering. Jag har lärt mig att kombinera hantverksskicklighet med kreativt tänkande och att ta hänsyn till både funktion och estetik i mitt arbete.</p>
            <p className="text-start mb-1 pb-1" style={{ fontFamily: 'Montserrat-Regular' }}>Ni finner mig i M&C Gardinateljés härliga lokal på Farkostvägen 2, Lidingö.</p>
            <p className="text-start mb-1 pb-5" style={{ fontFamily: 'Montserrat-Regular' }}>I vårt sortiment hittar du ett brett utbud av tyger i olika material, färger och mönster för att det ska passa just din stil och smak.</p>
            <div className="d-flex justify-content-center pb-5">
              <ReactPlayer
                url='/assets/MalmStensSoMeAnzelika.mp4'
                controls={true}
                width='450px'
                height='auto'
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
});

export default About;
// #5D4A80