import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import { useMediaQuery } from 'react-responsive';

const PrivacyPolicy = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const sectionPaddingTop = isMobileOrTablet ? 86 : 111; // Высота paddingTop для разных устройств
  const logoHeight = isMobileOrTablet ? 60 : 85;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="privacy-policy-wrapper">
      <Menu logoHeight={logoHeight} sectionPaddingTop={sectionPaddingTop} />
      <div className={`content pt-5 text-start ${isLoaded ? 'loaded' : ''}`} style={{ marginTop: '111px', marginBottom: '50px', color: 'white' }}>
        <h1 className="text-center pb-2">Integritetspolicy</h1>
        <p>Ikraftträdandedatum: [2024-10-09]</p>

        <p>Vi respekterar din integritet och strävar efter att skydda dina personuppgifter.Denna integritetspolicy förklarar vilken personlig data vi samlar in, hur vi använder den och hur du kan kontrollera din data.</p>

        <h4>1. Insamling av personlig information</h4>

        <p>Vi kräver inte att du lämnar någon personlig information när du besöker vår webbplats. Om du kontaktar oss via formulär på webbplatsen, e-post eller telefon kan vi dock samla in följande personliga information:</p>
        <ul>
          <li>Namn</li>
          <li>E-postadress</li>
          <li>Telefonnummer</li>
          <li>Annan information som du tillhandahåller i ditt meddelande</li>
        </ul>
        <p>Vi använder endast denna information för att kommunicera med dig angående din förfrågan eller våra tjänster. Vi behåller inte din personliga data längre än nödvändigt för att besvara din förfrågan eller uppfylla våra åtaganden.</p>

        <h4>2. Ingen användning av cookies</h4>

        <p>Vi använder inte cookies eller någon form av spårningstekniker på vår webbplats. Om du stöter på cookies under ditt besök kan de vara relaterade till tredjepartstjänster som vi inte kontrollerar.</p>

        <h4>3. Hur vi skyddar din data</h4>

        <p>Vi vidtar åtgärder för att skydda dina personuppgifter mot obehörig åtkomst och behandling i enlighet med GDPR. Vi implementerar administrativa, tekniska och fysiska säkerhetsåtgärder för att skydda din personliga information mot obehörig åtkomst eller avslöjande. Vi lagrar din data säkert och raderar den när den inte längre behövs för att uppfylla sitt syfte.</p>

        <h4>4. Dina rättigheter</h4>

        <p>Som användare har du rätt att begära åtkomst till, rättelse eller radering av dina personuppgifter. Du kan också återkalla ditt samtycke när som helst. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss.</p>

        <h4>5. Användarens samtycke</h4>

        <p>Eftersom vi inte använder cookies eller annan spårningsteknik på vår webbplats, behövs inget samtycke för cookies. Om du kontaktar oss via formuläret på webbplatsen ger du ditt samtycke till att vi behandlar dina personuppgifter i enlighet med denna integritetspolicy.</p>

        <h4>6. Ändringar av denna policy</h4>

        <p>Vi förbehåller oss rätten att uppdatera denna policy vid behov för att återspegla ändringar i lagkrav eller operativa behov. Alla uppdateringar kommer att publiceras på denna sida, med det senaste uppdateringsdatumet angivet högst upp.</p>

        <h4>7. Kontaktinformation</h4>

        <p>Om du har några frågor angående denna integritetspolicy eller hur vi hanterar din data, vänligen kontakta oss via kontaktform.</p>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy;