import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Menu from './Menu';
import Footer from './Footer';
import PhotoOverlay from './PhotoOverlay';
import data from '../data/gallery.json';
import { IconContext } from 'react-icons';
import { FaCircleArrowUp } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';

const iconStyle = { color: "#E7AC5A", size: "38px" };

const ImageGallery = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showScroll, setShowScroll] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false); // Флаг для загрузки всех изображений
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const sectionPaddingTop = isMobileOrTablet ? 86 : 111; // Высота paddingTop для разных устройств
  const logoHeight = isMobileOrTablet ? 60 : 85; // Высота логотипа для разных устройств

  // Проверка скроллинга вверх
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.scrollY > 100) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 100) {
        setShowScroll(false);
      }
    };

    const handleScroll = () => {
      checkScrollTop();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showScroll]);

  // Обработчик загрузки изображений
  useEffect(() => {
    const loadImagePromises = data.map((image) => {
      return new Promise((resolve) => {
        const img = new window.Image(); // Используем стандартный объект Image для загрузки
        img.src = image.source;
        img.onload = resolve; // Когда изображение загружено, разрешаем промис
      });
    });

    // Когда все изображения загружены, убираем спиннер и показываем галерею
    Promise.all(loadImagePromises).then(() => {
      setAllImagesLoaded(true); // Устанавливаем флаг полной загрузки
    });
  }, []);

  const openImageOverlay = (index) => {
    setSelectedIndex(index);
  };

  const closeImageOverlay = () => {
    setSelectedIndex(null);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="page-container">
      <Menu logoHeight={logoHeight} sectionPaddingTop={sectionPaddingTop} />
      <div className="content-wrap">
        {!allImagesLoaded ? (
          <div style={{ marginTop: isMobileOrTablet ? '200px' : '350px' }}>
            <Spinner animation="border" variant="warning" /> {/* Спиннер пока загружаются все изображения */}
          </div>
        ) : (
          <div className="gallery section-container" style={{ marginTop: '111px', marginBottom: '50px' }}>
            <div fluid="true" className="pt-5">
              <div className="container">
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 6 }}>
                  <Masonry gutter="10px">
                    {data.map((image, index) => (
                      <div key={index} onClick={() => openImageOverlay(index)}>
                        <Image
                          className="custom-style-img" // Плавное появление всех изображений
                          src={image.source}
                          fluid
                          alt={image.alt}
                        />
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

                {selectedIndex !== null && (
                  <PhotoOverlay
                    images={data}
                    activeIndex={selectedIndex}
                    onClose={closeImageOverlay}
                  />
                )}
              </div>

              <h5 style={{ color: 'white', fontFamily: 'Montserrat-Regular' }} className="pt-5">
                Fler arbeten kan ses på mitt Instagram-konto.
              </h5>
            </div>

            {showScroll && (
              <div className="scroll-to-top" onClick={scrollToTop}>
                <IconContext.Provider value={iconStyle}>
                  <FaCircleArrowUp />
                </IconContext.Provider>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ImageGallery;
