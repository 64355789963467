import React, { useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IconContext } from 'react-icons';
import { BsInstagram } from "react-icons/bs";

const iconStyle = { color: "#BB6380", size: "24px" };

const Menu = ({ activeSection, logoHeight, sectionPaddingTop }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const handleGoToHome = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }
    
  const handleMenuToggle = () => {
    setExpanded(!expanded);
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      navigate(`/?section=${sectionId}`);
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          const paddingTop = logoHeight === 60 ? 80 : sectionPaddingTop;
          const scrollToY = section.offsetTop - paddingTop;
          window.scrollTo({ top: scrollToY, behavior: 'smooth'});

          const newUrl = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
        }
      }, 150);
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        const paddingTop = logoHeight === 60 ? 80 : sectionPaddingTop; // Используем sectionPaddingTop в зависимости от logoHeight
        const scrollToY = section.offsetTop - paddingTop;
        window.scrollTo({ top: scrollToY, behavior: 'smooth' });

        // Убираем парамерт из URL после завершения скролинга
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    }
    setExpanded(false);
  };  

  return (
    <Navbar 
      fixed="top"
      expand="lg"
      expanded={expanded}
      onToggle={handleMenuToggle}
      bg="white" 
      data-bs-theme="light"
      style={{
        top: 0,
      }}
      className="navbar-container"
    >
      <Container fluid className='mx-5 px-0'>
        <Navbar.Brand id="home" onClick={handleGoToHome} style={{ cursor: 'pointer' }}>
          <img
            src="logo.png"
            height={logoHeight} // Используйте переданное значение logoHeight для установки размера логотипа
            className="d-inline-block align-top"
            alt="Logo"
          />          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link 
              onClick={() => scrollToSection('section-1')}
              className={activeSection === 'section-1' ? 'active': ''}
              style={{ fontFamily: 'Montserrat-Medium' }}
            >
              OM MIG
            </Nav.Link>
            <Nav.Link 
              onClick={() => scrollToSection('section-2')}
              className={activeSection === 'section-2' ? 'active': ''}
              style={{ fontFamily: 'Montserrat-Medium' }}
            >
              TJÄNSTER
            </Nav.Link>
            <Nav.Link 
              onClick={() => scrollToSection('section-3')}
              className={activeSection === 'section-3' ? 'active': ''}
              style={{ fontFamily: 'Montserrat-Medium' }}
            >
              KONTAKT
            </Nav.Link>
            <Nav.Link 
              as={Link} to="/bildgalleri" 
              className={location.pathname === '/bildgalleri' ? 'active' : ''} 
              style={{ fontFamily: 'Montserrat-Medium' }}
            >
              BILDGALLERI
            </Nav.Link>
            <Navbar.Text>
              <a href="https://www.instagram.com/lika_tapetserare/" target="_blank" rel="noopener noreferrer" aria-label='Följ Instagram-profilen'>
                <IconContext.Provider value={iconStyle}>
                  <BsInstagram />
                </IconContext.Provider>
              </a>                                  
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;