import React, { useEffect } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const PhotoOverlay = ({ images, activeIndex, selectedIndex, onClose }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            };
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const renderImages = (images) => {
        return images.map((image) => ({
            src: image.largeSource ? image.largeSource : image.source,
            alt: image.title
        }));
    };

    return (
        <div className='photo-overlay'>
            <button className='close-button' onClick={onClose} style={{ zIndex: '2' }}>
                &times;
            </button>
            <div>
                <Carousel
                    images={selectedIndex !== null && selectedIndex !== undefined ? renderImages([images[selectedIndex]]) : renderImages(images)}
                    index={selectedIndex !== null && selectedIndex !== undefined ? 0 : activeIndex}
                    isMaximized={true}
                    transitionSpeed={4}
                    hasMediaButton={false}
                    hasSizeButton={false}
                    hasIndexBoard={false}
                    zIndexAtMax={1}
                    isLoop={false}
                    hasThumbnails={false}
                    hasDotButtons='bottom'
                    hasCaptions='bottom'
                />
            </div>
        </div>
    )
}

export default PhotoOverlay;