import React, { useRef, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from './Menu';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import { IconContext } from 'react-icons';
import { FaCircleArrowUp } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';

const iconStyle = { color: "#E7AC5A", size: "38px" };

const Home = () => {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const sectionPaddingTop = isMobileOrTablet ? 86 : 111; // Высота paddingTop для разных устройств
  const logoHeight = isMobileOrTablet ? 60 : 85;
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const [activeSection, setActiveSection] = useState('home');
  const [showScroll, setShowScroll] = useState(false);
  const [sectionToScroll, setSectionToScroll] = useState(null); // Состояние для хранения секции для скроллинга
  const location = useLocation(); // Используем useLocation для отслеживания параметров URL

  // Логика обработки параметра 'section' из URL
  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section');

    if (section) {
      setSectionToScroll(section);
    }
  }, [location]);

  // Скроллинг к нужной секции перед завершением рендера
  useLayoutEffect(() => {
    if (sectionToScroll) {
      const sectionElement = document.getElementById(sectionToScroll);
      if (sectionElement) {
        const offsetTop = sectionElement.offsetTop - 111; // Учет отступа для корректного скроллинга
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        setSectionToScroll(null); // Сбрасываем состояние после выполнения скроллинга
      }
    }
  }, [sectionToScroll]);

  // Обработчик скроллинга для установки активной секции и отображения кнопки "наверх"
  useLayoutEffect(() => {
    const handleScroll = () => {
      const section1Top = section1Ref.current.offsetTop - sectionPaddingTop;
      const section2Top = section2Ref.current.offsetTop - sectionPaddingTop;
      const section3Top = section3Ref.current.offsetTop - sectionPaddingTop;
      const currentPosition = window.scrollY;

      if (currentPosition >= section1Top && currentPosition < section2Top && activeSection !== 'section-1') {
        setActiveSection('section-1');
      } else if (currentPosition >= section2Top && currentPosition < section3Top && activeSection !== 'section-2') {
        setActiveSection('section-2');
      } else if (currentPosition >= section3Top && activeSection !== 'section-3') {
        setActiveSection('section-3');
      } else if (currentPosition < section1Top && activeSection !== 'home') {
        setActiveSection('home');
      }

      // Показываем кнопку скроллинга наверх
      if (currentPosition > 100 && !showScroll) {
        setShowScroll(true);
      } else if (currentPosition <= 100 && showScroll) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection, sectionPaddingTop, showScroll]);

  return (
    <div>
      <Menu activeSection={activeSection} logoHeight={logoHeight} sectionPaddingTop={sectionPaddingTop} />
      <About id="section-1" ref={section1Ref} />
      <Services id="section-2" ref={section2Ref} />
      <Contact id="section-3" ref={section3Ref} />
      {showScroll && (
        <div className="scroll-to-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <IconContext.Provider value={iconStyle}>
            <FaCircleArrowUp />
          </IconContext.Provider>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Home;
