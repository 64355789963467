import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

const Contact = forwardRef(({ id }, ref) => {
  const { control, 
      handleSubmit,
      formState: { errors },
      reset 
    } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      subject: '',
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isError, setIsError] = useState(false);
  
  const handleClose = () => setShowModal(false);

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  };

  const submitForm = (data, e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...data})
    })    
      .then((response) => {
        if (response.ok) {
          reset();
          setModalMessage('Tack för ditt meddelande! Jag återkommer till dig så snart som möjligt.');
          setIsError(false);
        } else {
          reset();
          setModalMessage('Det uppstod ett problem med din inskickning. Försök igen.');
          setIsError(true);
        }
        setShowModal(true);
      })
      .catch(error => {
        reset();
        setModalMessage('Ett fel uppstod när formuläret skickades. Försök igen.');
        setIsError(true);
        setShowModal(true);
      });
    e.preventDefault();
  };

  return (
    <div id={id} ref={ref} className="section-container">
      <Container fluid style={{ backgroundColor: '#DDB2C0'}} className="mt-0 px-0">
          <h3 className="pt-5 pb-3 text-uppercase" style={{ color: '#4D3280', fontFamily: 'Montserrat-Semibold' }}>Kontakta mig</h3>
          <Row className='justify-content-center pb-1'>
            <Col xs={12} sm={12} md={10} lg={6} xl={6} xxl={5}>
              <div className="pb-3">
                <p className="text-start mb-1" style={{ fontFamily: 'Montserrat-Regular' }}>Har du frågor om möbeltapetsering eller vill du veta mer om de tjänster jag erbjuder?</p>
                <p className="text-start" style={{ fontFamily: 'Montserrat-Regular' }}>Fyll i formuläret nedan, så återkommer jag till dig inom kort!</p>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs={12} sm={12} md={10} lg={6} xl={6} xxl={5} className='mb-5'>
              <Form name='contact' method='POST' noValidate onSubmit={handleSubmit(submitForm)}>
                <input type="hidden" name="form-name" value="contact" />
                <Form.Group className='mb-3' controlId='validationInputName'>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type='text'
                        placeholder='Namn'
                        isInvalid={errors.name}
                        name='name'
                        autoComplete='off'
                        style={{ fontFamily: 'Montserrat-Regular' }}
                      />
                    )}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      Namn obligatorisk
                    </Form.Control.Feedback>
                  )}                  
                </Form.Group>
                <Form.Group className='mb-3' controlId='validationInputEmail'>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true, pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/ }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type='text'
                        placeholder='E-post'
                        isInvalid={errors.email}
                        name='email'
                        autoComplete='off'
                        style={{ fontFamily: 'Montserrat-Regular' }}
                      />
                    )}
                  />
                  {errors.email?.type === "pattern" && (
                    <Form.Control.Feedback type="invalid">
                      Ogiltig e-postadress
                    </Form.Control.Feedback>
                  )}
                  {errors.email?.type === "required" && (
                    <Form.Control.Feedback type="invalid">
                      E-postadress obligatorisk
                    </Form.Control.Feedback>
                  )}  
                </Form.Group>
                <Form.Group className='mb-3' controlId='validationSubject'>
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        aria-label='ämne'
                        isInvalid={errors.subject}
                        name='subject[]'
                        style={{ fontFamily: 'Montserrat-Regular' }} 
                      >
                        <option value=''>Välj ämne</option>
                        <option value='privat'>Privat</option>
                        <option value='företag'>Företag</option>
                      </Form.Select> 
                    )}
                  />
                  {errors.subject && (
                    <Form.Control.Feedback type="invalid">
                      Välj ämne
                    </Form.Control.Feedback>
                  )}                  
                </Form.Group>
                <Form.Group className='mb-3' controlId='validationMessage'>
                  <Controller
                    name="message"
                    control={control}
                    rules={{ required: true, minLength: 10, maxLength: 1000 }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}  
                        as='textarea'
                        rows={12}
                        placeholder='Meddelande'
                        isInvalid={errors.message}
                        name='message'
                        style={{ fontFamily: 'Montserrat-Regular' }} 
                      />
                    )}
                  />
                  {errors.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors.message?.type === "required" && 'Vänligen ange ditt meddelande'}
                      {errors.message.type === 'minLength' && 'Meddelandet är för kort'}
                      {errors.message.type === 'maxLength' && 'Meddelandet är för långt'}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <div className="pb-3">
                  <p className="text-start mb-1" style={{ fontFamily: 'Montserrat-Regular' }}>Lika Möbeltapetsering samtycker till att inte lämna ut någon konfidentiell information. Dina personuppgifter behandlas i enlighet med vår <Link to="/integritetspolicy" style={{ color: 'black'}}>Integritetspolicy.</Link></p>
                </div>
                <Form.Group className='d-flex justify-content-end'>
                  <Button type='submit' className="px-4" style={{ borderColor: "#4D3280", backgroundColor: '#4D3280', color: 'white', fontWeight: 'light', fontFamily: 'Montserrat-Regular' }}>
                    Skicka
                  </Button> 
                </Form.Group>
              </Form>
            </Col>
          </Row>
      </Container>

      {/* Модальное окно для отображения статуса отправки */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: 'Montserrat-Medium' }}>{isError ? 'Fel' : 'Lyckat!'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Montserrat-Regular' }}>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button style={{ borderColor: "#4D3280", backgroundColor: '#4D3280', color: 'white', fontWeight: 'light', fontFamily: 'Montserrat-Medium' }} onClick={handleClose}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>      
    </div>
  )
});

export default Contact;