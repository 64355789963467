import React, { forwardRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import PhotoOverlay from './PhotoOverlay';
import data from '../data/services.json';

const Services = forwardRef(({ id }, ref) => {
  const [selectedService, setSelectedService] = useState(null);
  
  const openImageOverlay = (service) => {
    setSelectedService(service);
  };

  const closeImageOverlay = () => {
    setSelectedService(null);
  };

  return (
    <div id={id} ref={ref} className="section-container">
      <div style={{ backgroundColor: '#EDD0A7' }} className="mt-0 px-0 pb-5">
        <h3 className="pt-5 pb-3 text-uppercase" style={{ color: '#4D3280', textAlign: 'center', fontFamily: 'Montserrat-Semibold' }}>Tjänster</h3>
        <div className="container-wrapper">
          <div className="d-flex justify-content-center flex-wrap mb-0">
            {data.map((service, index) => (
              <div key={index} className="card-container pb-5">
                <div className='image-container' onClick={() => openImageOverlay(service)}>
                  <Image
                    className='custom-style-img'
                    src={service.source}
                    fluid
                    alt={service.alt}
                  />
                </div>
                <h5 style={{ textAlign: 'left', color: '#4D3280', fontFamily: 'Montserrat-Semibold' }} className="mt-3">{service.name}</h5>
                <p style={{ textAlign: 'left', color: '#4D3280', fontFamily: 'Montserrat-Regular' }} className="mb-0">{service.description}</p>
                <Button
                  variant="light"
                  className='d-flex inline-block ps-0'
                  style={{ backgroundColor: '#EDD0A7', borderColor: '#EDD0A7', color: '#4D3280' }}
                  onClick={() => openImageOverlay(service)}
                >
                  <p style={{ fontFamily: 'Montserrat-Light' }}>GALLERI<span className='ps-2'>{'>'}</span></p>
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedService && selectedService.related &&(
        <PhotoOverlay
          images={selectedService.related}
          onClose={closeImageOverlay}
        />
      )}
    </div>
  )
});

export default Services;
